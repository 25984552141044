import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import UpcomingEventsEn from '../components/UpcomingEventsEn';
import UpcomingEvents from '../components/UpcomingEvents';
export { default as Head } from '../components/Head';

// import Layout from "../components/Layout";

function Page({ data }) {
  const page = data.page;
  const location = useLocation();

  return (
    <>
      {page.frontmatter.template === 'sub-page' ? (
        <Layout>
          <div className='container content mb-6 copy main'>
          <MDXRenderer>{page.body}</MDXRenderer>
          </div>
      </Layout>
      ) : page.frontmatter.template === 'event' ? (
        <Layout>
          <div class="content section-content event-wrapper main">
            <div class="columns is-multiline">
              <div class="content-intro column">
                <h1><span>{page.frontmatter.title}</span></h1>
                <div className="line-wrapper">
                  <div className="h2underline"></div>
                  <div className="marginline"></div>
                </div>
              </div>
              <div className='column'>
              <div class="block event-copy">
                <div class="content">
                  <MDXRenderer>{page.body}</MDXRenderer>
                </div>
              </div> 
            </div>
          </div>
          </div>
            { location.pathname.split('/')[1] === 'en' ? (
            <div className="container p-5 mb-6 mt-6">
              <div className='page-title pb-3 pre-events'>
                <div className="columns">
                  <div className="left">
                    <h1>More Upcoming<br/><span>Pre-Events</span></h1>
                    <div className="line-wrapper">
                      <div className="h2underline"></div>
                      <div className="marginline"></div>
                    </div>
                  </div>
                </div>
              </div>
              <UpcomingEventsEn />
            </div>
          ) : (
             <div className="container p-5 mb-6 main mt-6">
              <div className='page-title pb-3 pre-events'>
                <div className="columns">
                  <div className="left">
                    <h1>Mais Próximos<br/><span>Pré-Eventos</span></h1>
                    <div className="line-wrapper">
                      <div className="h2underline"></div>
                      <div className="marginline"></div>
                    </div>
                  </div>
                </div>
              </div>
              <UpcomingEvents />
            </div>
          )
        }
        </Layout>
        ) : page.frontmatter.template === 'about' ? (
          <Layout>
            <div className='page-title about-title'>
              <div className="columns">
                <div className="left">
                  <h1><span>{page.frontmatter.title}</span></h1>
                  <div className="line-wrapper">
                    <div className="h2underline"></div>
                    <div className="marginline"></div>
                  </div>
                </div>
              </div>
            </div>
          <div className='container content mb-6 copy main'>
          <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </Layout>
      ) : (
      <Layout>
        <div>
          <MDXRenderer>{page.body}</MDXRenderer>
        </div>
      </Layout>
    )}
  </>
  );
}

export default Page;

export const pageQuery = graphql`
  query ($id: String) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        template
        title
        slug
        excerpt
      }
      file: parent {
        ... on File {
          modifiedTime
        }
      }
      body
    }
  }
`;

// export const Head = ({
//   data: {
//     mdx: {
//       frontmatter: { title },
//     },
//   },
// }) => {
//   return <SEO title={title} />
// }
